@layer components {
    .game-header__container {
        @apply bg-overlay flex flex-col bg-cover bg-center;
    }

    .game-header {
        @apply mx-auto flex flex-1 flex-col flex-wrap px-8 text-white;

        &__left-column,
        &__right-column {
            @apply flex w-full flex-col items-center justify-center;
        }

        &__right-column {
            @apply pt-3 text-center font-medium uppercase;
        }

        &__title {
            @apply w-full font-display text-4xl font-extrabold uppercase leading-9;
            letter-spacing: -2.77px;
        }

        &__description {
            @apply w-full flex-1 overflow-hidden break-words text-lg text-neutral-600;
        }
    }

    .game-missions {
        @apply mx-auto mt-12 flex select-none py-8;

        > :first-child,
        > :last-child {
            @apply pointer-events-none hidden pl-0 opacity-25;
        }
    }

    .game-missions__wrapper {
        @apply flex w-full flex-none overflow-x-scroll;
        background: linear-gradient(
            180deg,
            transparent 0%,
            transparent 25%,
            white 75%
        );
    }

    .game-mission__container {
        @apply px-4;
        flex: 0 0 calc(100% / 2);
        min-width: 20rem;
    }

    .game-mission {
        @apply flex h-full items-end bg-cover bg-center p-6 text-white;
        height: 210px;
        transition: transform 200ms ease;
        transform-origin: bottom center;
        box-shadow: 2px 2px 35px 0 hsla(0, 0%, 0%, 0.5);

        &:hover,
        &:focus {
            transform: scale(1.1);
        }
    }

    .game-nav {
        @apply mx-auto flex flex-col border-b border-neutral-100 px-8 text-sm;

        .nav-item {
            @apply mr-10 px-8 py-6 font-normal text-neutral-700;
        }

        .nav-item--active {
            @apply font-bold text-black;
        }

        .nav-item::after {
            @apply absolute;
        }
    }

    @screen sm {
        .game-header {
            @apply flex-row;
        }

        .game-header__title {
            @apply text-6xl;
            line-height: 4rem;
        }

        .game-header__left-column {
            @apply w-9/12;
        }

        .game-header__right-column {
            @apply w-3/12;
        }

        .game-missions {
            margin-left: calc(-100% / 3);
            margin-right: calc(-100% / 3);

            > :first-child,
            > :last-child {
                @apply block;
            }
        }

        .game-missions__wrapper {
            @apply overflow-x-hidden;
        }

        .game-mission__container {
            flex-basis: calc(100% / 3);
        }

        .game-nav {
            @apply flex-row;
        }
    }

    @screen md {
        .game-header__title {
            font-size: 5rem;
            line-height: 5rem;
        }
    }

    @screen xl {
        .game-header__container {
            /* Game header should never hide extra content on large screens*/
            min-height: calc(100vh - 5rem);
        }

        .game-header__title {
            font-size: 6rem;
            line-height: 6rem;
        }
    }
}
