.login-container {
    @apply flex min-h-screen flex-col items-center justify-center bg-cover bg-center;
    background-color: rgba(0, 0, 0, 0.5);
    background-image: url('../../img/portal/default_login_background.jpg');
    background-blend-mode: darken;
}

.login-wrapper {
    @apply w-full;
    max-width: 512px;
}

.login-footer {
    @apply mt-4 inline-block h-6 select-none bg-contain bg-right bg-no-repeat pr-24 text-xs font-bold uppercase leading-6 text-white opacity-75;
    background-image: url('../../img/portal/InThere_logo_2020.svg');
}
