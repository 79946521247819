@layer components {
    .input-group {
        @apply mb-4 flex flex-col;
        min-width: 300px;

        &--row {
            @apply flex-row;
        }

        &__input {
            @apply border-b border-neutral-500 py-2;
        }

        &__input:focus {
            @apply outline-none border-primary;
        }

        &__label {
            @apply select-none text-sm font-semibold text-neutral-900;

            &--checkbox {
                @apply flex cursor-pointer items-center font-normal;
            }
        }

        &__checkbox {
            @apply absolute h-0 w-0 opacity-0;
        }

        &__checkbox-btn {
            @apply mr-1 inline-block h-5 w-5 rounded border border-neutral-700 p-1 text-center text-white;
        }

        &__checkbox:checked + &__checkbox-btn {
            @apply border-primary bg-primary text-on-primary;
        }
    }
}
