@layer components {
    .answer-row {
        @apply outline-none mb-2 flex cursor-pointer select-none items-center bg-white p-4 text-left text-neutral-900;
        box-shadow: 0 10px 24px 0 rgba(29, 42, 68, 0.12);
    }

    .answer-row[disabled] {
        @apply cursor-default;
    }

    .answer-row:focus {
        @apply outline-none;
    }

    .answer-row__letter {
        @apply mr-4 inline-block h-10 w-10 rounded border border-neutral-500 bg-neutral-200 p-3 text-center text-xl font-extrabold uppercase leading-4 text-neutral-900;
    }

    .answer-row__checkbox {
        @apply hidden;
    }

    .answer-row__checkbox:checked + .answer-row,
    .answer-row--selected {
        @apply bg-primary font-bold text-on-primary;
        box-shadow: 0 10px 24px 0 theme('colors.primary.DEFAULT');

        .answer-row__letter {
            @apply bg-white text-primary;
        }
    }

    .answer-row--wrong {
        @apply bg-danger;
        box-shadow: 0 10px 24px 0 theme('colors.danger.DEFAULT');

        .answer-row__letter {
            @apply text-danger;
        }
    }

    .answer-row--correct {
        @apply bg-success;
        box-shadow: 0 10px 24px 0 theme('colors.success.DEFAULT');

        .answer-row__letter {
            @apply text-success;
        }
    }
}
