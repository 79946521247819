@layer components {
    .mission-filter__row {
        @apply my-4 flex items-center text-sm text-neutral-700;

        &--active {
            @apply font-bold text-neutral-900;

            .mission-filter__row-icon {
                @apply border-accent bg-accent text-accent;
            }
        }
    }

    .mission-filter__row-icon {
        @apply mr-2 h-5 w-5 flex-none items-center justify-center rounded-full border-2 border-neutral-500 text-center font-bold leading-4;
        font-size: 10px;
    }
}
