@layer components {
    .nav-item__dropdown-container {
        @apply relative mt-4 block bg-white;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);

        &::after {
            @apply absolute border-white;
            right: 8px;
            bottom: 100%;
            content: '';
            border-width: 16px;
            border-top-color: transparent;
            border-right-color: transparent;
            border-left-color: transparent;
        }

        &::before {
            @apply absolute;
            z-index: -1;
            top: -9px;
            right: 8px;
            width: 32px;
            height: 32px;
            content: '';
            transform: rotate(45deg);
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
        }
    }

    .nav-item__dropdown {
        @apply block whitespace-nowrap p-3 pr-8 font-semibold uppercase text-on-light;
    }
}
