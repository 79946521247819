.icon .a,
.icon .cls-1 {
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.5px;
}

.icon--light .a,
.icon--light .cls-1 {
    stroke-width: 0.5px;
}

.icon--1 .a,
.icon--light .cls-1 {
    stroke-width: 1px;
}
