@layer components {
    .dashboard-card {
        box-shadow: 0 10px 24px 0 rgba(29, 42, 68, 0.12);
    }

    .dashboard-card--large {
        @apply col-span-5 row-span-2;
    }

    .dashboard-card-icon {
        @apply absolute bottom-0 right-0 w-7/12 opacity-25;
        transform: translate(10%, 30%);
    }

    .dashboard-grid {
        grid-auto-rows: minmax(0, 1fr);
    }
}
