@layer components {
    .game-tile {
        @apply bg-overlay relative col-span-12 row-span-1 h-full w-full bg-cover bg-center p-3 text-white;
        min-height: 400px;

        &--large {
            @apply flex flex-col py-12;
        }

        &__description {
            @apply my-5 flex-1 break-words text-neutral-300;
            text-overflow: ellipsis;
        }

        &__title {
            @apply w-full font-display text-4xl font-bold uppercase leading-9 transition duration-200;
            letter-spacing: -0.92px;
        }

        &__left-column,
        &__right-column {
            @apply flex h-full w-full flex-col items-center justify-center;
        }

        &__left-column {
            @apply items-start justify-between;
        }

        &__right-column {
            @apply text-center;
        }

        &--large &__title {
            @apply text-center text-6xl leading-none;
            letter-spacing: -2.31px;
        }

        &--large &__description {
            @apply text-2xl;
        }

        &__overlay {
            @apply pointer-events-none absolute top-0 bottom-0 right-0 left-0 flex flex-col items-start justify-end p-12 opacity-0 transition-opacity delay-200 duration-500;
            background-color: rgba(0, 0, 0, 0.5);
        }

        &__content {
            @apply absolute top-0 bottom-0 right-0 left-0 flex flex-col items-start justify-end p-12;
        }

        &:hover &__overlay {
            @apply pointer-events-auto opacity-100;
        }

        &:hover > &__content > &__title {
            @apply opacity-0;
            transform: translateY(-300px);
            opacity: 0;
        }

        .progress-bar__wrapper {
            @apply absolute inset-x-0 px-3;
            bottom: -1rem;
        }
    }

    @screen sm {
        .game-tile--large {
            @apply flex-row py-16;

            .game-tile__title {
                @apply text-left text-6xl;
            }

            .game-tile__left-column {
                @apply ml-auto w-7/12;
            }

            .game-tile__right-column {
                @apply mr-auto w-3/12;
            }
        }
    }

    @screen md {
        .game-tile:not(.game-tile--large) {
            @apply col-span-6 py-10;
        }

        .game-tile--large {
            @apply py-20;

            .game-tile__title {
                font-size: 5rem;
            }
        }
    }

    @screen xl {
        .game-tile:not(.game-tile--large) {
            @apply col-span-4;
        }

        .game-tile--large {
            @apply py-24;

            .game-tile__title {
                font-size: 6rem;
            }
        }
    }
}
