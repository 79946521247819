@layer components {
    .badge {
        @apply whitespace-nowrap rounded border px-3 text-sm font-medium uppercase;
        line-height: 1rem;

        &--primary {
            @apply border-primary bg-primary-light text-primary;
        }

        &--lg {
            @apply py-1 text-lg;
        }
    }
}
