@layer components {
    .profile-name-editor {
        @apply flex items-center;
    }

    .profile-name-editor__input {
        @apply mb-4 w-full border-b border-transparent bg-white font-display text-2xl font-extrabold leading-none text-neutral-900;

        &:focus {
            outline-color: theme('colors.primary.DEFAULT');
        }
    }

    .profile-name-editor:hover > .profile-name-editor__button {
        @apply opacity-100;
    }

    .profile-name-editor__button {
        @apply opacity-0 transition-opacity duration-100 ease-in-out;
    }

    @screen sm {
        .profile-name-editor__input {
            @apply text-3xl;
        }
    }

    @screen md {
        .profile-name-editor__input {
            @apply text-4xl;
        }
    }

    @screen lg {
        .profile-name-editor__input {
            @apply text-5xl;
        }
    }

    @screen xl {
        .profile-name-editor__input {
            @apply text-5xl;
        }
    }
}
