.highcharts-color-0 {
    @apply fill-primary;

    &.highcharts-tracker {
        @apply fill-primary;
    }

    .highcharts-area {
        @apply fill-primary;
        opacity: 0.5;
    }

    .highcharts-graph {
        @apply stroke-primary;
        opacity: 0.5;
    }

    .highcharts-point {
        @apply fill-primary;
    }
}

.highcharts-color-1 {
    @apply fill-accent;

    &.highcharts-tracker {
        @apply fill-accent;
    }

    .highcharts-area {
        @apply fill-accent;
        opacity: 0.5;
    }

    .highcharts-graph {
        @apply stroke-accent;
        opacity: 0.5;
    }

    .highcharts-point {
        @apply fill-accent;
    }
}

.highcharts-color-2 {
    fill: #b8e35d;

    &.highcharts-tracker {
        fill: #b8e35d;
    }

    .highcharts-area {
        fill: #b8e35d;
        opacity: 0.5;
    }

    .highcharts-graph {
        stroke: #b8e35d;
        opacity: 0.5;
    }

    .highcharts-point {
        fill: #b8e35d;
    }
}

.bg-color-2 {
    background-color: #b8e35d;
}

.highcharts-color-3 {
    fill: #2cd9c5;

    &.highcharts-tracker {
        fill: #2cd9c5;
    }

    .highcharts-area {
        fill: #2cd9c5;
        opacity: 0.5;
    }

    .highcharts-graph {
        stroke: #2cd9c5;
        opacity: 0.5;
    }

    .highcharts-point {
        fill: #2cd9c5;
    }
}

.bg-color-3 {
    background-color: #2cd9c5;
}

.highcharts-axis-labels,
.highcharts-data-labels,
.highcharts-caption {
    @apply font-body;
}

.highcharts-title,
.highcharts-subtitle,
.highcharts-axis-title {
    @apply font-display;
}
