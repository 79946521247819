@layer components {
    .button {
        @apply my-2 inline-flex cursor-pointer items-center justify-center rounded-full bg-center bg-no-repeat p-4 text-center font-body font-bold uppercase;

        &:not(&--disabled):hover {
            @apply bg-neutral-100;
        }

        &--primary {
            @apply border-primary bg-primary text-on-primary;

            &:not(.button--disabled):hover {
                @apply border-primary-dark bg-primary-dark;
            }
        }

        &--neutral {
            @apply border-neutral-500 bg-neutral-500 text-neutral-900;

            &:not(.button--disabled):hover {
                @apply border-neutral-700 bg-neutral-700;
            }
        }

        &--dark {
            @apply border-neutral-900 bg-neutral-900 text-white;

            &:not(.button--disabled):hover {
                @apply border-black bg-black;
            }
        }

        &--accent {
            @apply border-accent bg-accent text-on-accent;

            &:not(.button--disabled):hover {
                @apply border-accent-dark bg-accent-dark;
            }
        }

        &--disabled,
        &[disabled] {
            @apply cursor-default border-neutral-300 bg-neutral-300 text-white;
        }

        &--locked {
            @apply pointer-events-none border-4 border-neutral-500 text-neutral-500 opacity-50;
        }

        &--long {
            @apply py-2 px-8;
        }

        &--rounded {
            @apply h-12 w-12 p-0;
        }

        &--outline {
            @apply border border-neutral-700 bg-white text-neutral-700;

            &:not(.button--disabled):hover {
                @apply border-on-light bg-white text-on-light;
            }
        }

        &--outline-primary {
            @apply border border-primary bg-white text-primary;

            &:not(.button--disabled):hover {
                @apply border-primary-dark bg-white text-primary-dark;
            }
        }

        &:focus {
            @apply outline-none;
        }

        &--large-circle {
            width: 120px;
            height: 120px;
        }

        &--medium-circle {
            width: 80px;
            height: 80px;
        }

        &--outline-primary&--large-circle {
            @apply border-2;
        }
    }
}
