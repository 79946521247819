.nav-bar-container {
    @apply flex flex-wrap items-center pb-6 text-neutral-900;

    &.nav-bar--light {
        @apply text-white;
    }
}

#nav-toggle:checked ~ .nav-bar {
    @apply block w-full;
}

.nav-bar__toggle {
    @apply mr-4 cursor-pointer rounded leading-none;
}

.nav-bar__branding {
    @apply ml-4 bg-white p-3 pt-6;
}

.nav-bar__branding-logo {
    @apply h-16;
}

.nav-bar {
    @apply hidden pt-4;
}

.nav-item {
    @apply relative block px-4 py-2 font-body font-semibold uppercase transition-colors duration-100;
    letter-spacing: 1px;

    &::after {
        @apply inset-x-0 bottom-0 block h-1 w-full origin-left scale-x-0 transform bg-accent transition-transform duration-300;
        content: '';
    }

    &:hover,
    &--active {
        @apply text-accent;

        &::after {
            @apply scale-x-100 transform;
        }
    }
}

@screen lg {
    .nav-bar__toggle {
        @apply hidden;
    }

    .nav-bar__branding {
        @apply ml-12;
    }

    .nav-bar {
        @apply mr-12 flex;
    }

    .nav-item--small {
        @apply hidden;
    }

    .nav-item::after {
        @apply origin-center duration-100;
    }
}
