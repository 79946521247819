@layer components {
    .tooltip-container {
        @apply relative inline-block;
    }

    .tooltip {
        @apply absolute whitespace-nowrap rounded bg-neutral-300 px-4 py-2 text-center text-xs leading-tight text-neutral-900;
        transform-origin: center;
    }

    .tooltip::after {
        @apply absolute border-neutral-300;
        content: '';
        border-width: 8px;
    }

    .tooltip--top {
        top: -8px;
        left: 50%;
        transform: translate(-50%, -100%);

        &::after {
            top: 100%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(45deg);
            border-top-color: transparent;
            border-left-color: transparent;
        }
    }

    .tooltip--bottom {
        @apply top-0;
        left: 50%;
        transform: translate(-50%, 100%);

        &::after {
            top: 0;
            left: 50%;
            transform: translate(-50%, -50%) rotate(45deg);
            border-right-color: transparent;
            border-bottom-color: transparent;
        }
    }

    .tooltip--right {
        top: 50%;
        right: -8px;
        transform: translate(100%, -50%);

        &::after {
            top: 50%;
            left: 5px;
            transform: translate(-50%, -50%) rotate(45deg);
            border-top-color: transparent;
            border-right-color: transparent;
        }
    }

    .tooltip--left {
        top: 50%;
        left: -8px;
        transform: translate(-100%, -50%);

        &::after {
            top: 50%;
            left: calc(100% - 5px);
            transform: translate(-50%, -50%) rotate(45deg);
            border-bottom-color: transparent;
            border-left-color: transparent;
        }
    }
}
