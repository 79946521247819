@layer components {
    .quiz-results-bar-container {
        @apply relative py-20 text-neutral-900;
    }

    .quiz-results-bar {
        @apply relative h-12 w-full overflow-hidden rounded-full bg-neutral-500;
    }

    .quiz-results-bar__required-bar {
        @apply absolute inset-0 bg-accent;
        background-image: repeating-linear-gradient(
            -45deg,
            #333,
            #333 8px,
            #888 8px,
            #888 22px
        );
        background-blend-mode: lighten;
    }

    .quiz-results-bar__correct-bar {
        @apply absolute inset-0 bg-primary;
    }

    .quiz-results-bar__tooltip {
        @apply absolute inset-y-0 flex flex-col items-center py-4 text-center leading-tight;
    }

    .quiz-results-bar__tooltip-pin {
        @apply h-3 w-3 rounded-full border-2 border-neutral-900;
    }

    .quiz-results-bar__tooltip-line {
        @apply box-content h-16 bg-neutral-900 py-px;
        width: 2px;
    }

    .quiz-results-bar__tooltip-line--striped {
        background-color: unset;
        background-image: repeating-linear-gradient(
            to bottom,
            theme('colors.neutral.800'),
            theme('colors.neutral.800') 7px,
            rgba(0, 0, 0, 0) 7px,
            rgba(0, 0, 0, 0) 14px
        );
    }
}
