/* https://loading.io/spinner/interwind/-orbit-rotate-scale-ball */
@keyframes loading-spinner--rotate {
    0%,
    100% {
        animation-timing-function: cubic-bezier(0.2, 0, 0.8, 0.8);
    }
    50% {
        animation-timing-function: cubic-bezier(0.2, 0.2, 0.8, 1);
    }
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(180deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes loading-spinner--scale {
    0%,
    100% {
        animation-timing-function: cubic-bezier(0.2, 0, 0.8, 0.8);
    }
    50% {
        animation-timing-function: cubic-bezier(0.2, 0.2, 0.8, 1);
    }
    0% {
        transform: translate(-30px, -30px) scale(0);
    }
    50% {
        transform: translate(-30px, -30px) scale(1);
    }
    100% {
        transform: translate(-30px, -30px) scale(0);
    }
}

.loading-spinner > div {
    transform: translate(0px, -15px);
}

.loading-spinner > div > div {
    transform-origin: 100px 100px;
    animation: loading-spinner--rotate 1s linear infinite;
}

.loading-spinner > div > div > div {
    position: absolute;
    transform: translate(100px, 82px);
}

.loading-spinner > div > div > div > div {
    width: 60px;
    height: 60px;
    animation: loading-spinner--scale 1s linear infinite;
    border-radius: 50%;
    background: var(--primary);
}

.loading-spinner > div > div:last-child {
    animation-delay: -0.5s;
}

.loading-spinner > div > div:last-child > div > div {
    animation-delay: -0.5s;
    background: var(--accent);
}

.loading-spinner__container {
    display: inline-block;
    overflow: hidden;
    width: auto;
    height: auto;
    background: none;
}

.loading-spinner {
    position: relative;
    width: 100%;
    height: 100%;
    transform: translateZ(0) scale(1);
    transform-origin: 0 0;
    backface-visibility: hidden;
}

.loading-spinner div {
    box-sizing: content-box;
}
