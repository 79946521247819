@layer components {
    .flash-card {
        @apply absolute inset-0 w-full;
        outline: none;
        perspective: 1000px;
    }

    .flash-card-label {
        @apply absolute left-0 top-0 m-4 text-left text-sm font-bold;
    }

    .flash-card-inner {
        @apply relative h-full w-full transition-transform duration-500;
        transform-style: preserve-3d;
    }

    @media (prefers-reduced-motion) {
        .flash-card-inner {
            @apply transition-none;
        }
    }

    .flash-card--flipped .flash-card-inner,
    .flash-card__back {
        transform: rotateY(180deg);
    }

    .flash-card__front,
    .flash-card__back {
        @apply absolute h-full w-full;
        backface-visibility: hidden;
    }

    .flash-card__front,
    .flash-card__back {
        @apply flex items-center justify-center rounded-lg p-4;
    }

    .flash-card__front {
        @apply bg-primary text-on-primary;
    }

    .flash-card__back {
        @apply bg-accent text-on-accent;
    }

    /*
 * Container related
 */
    .flash-card-container {
        @apply relative m-auto flex h-full items-center justify-between;
        width: 400px;
        height: 300px;
    }

    .slide-left-enter-active,
    .slide-left-leave-active,
    .slide-right-enter-active,
    .slide-right-leave-active {
        @apply opacity-100 transition-all duration-500;
    }

    .slide-left-enter,
    .slide-left-leave-to,
    .slide-right-enter,
    .slide-right-leave-to {
        @apply translate-y-40 scale-0 transform opacity-25;
    }

    .slide-left-enter,
    .slide-right-leave-to {
        @apply origin-bottom-right translate-x-48;
    }

    .slide-left-leave-to,
    .slide-right-enter {
        @apply origin-bottom-left -translate-x-48;
    }
}
