@layer components {
    .mission-row {
        @apply mb-6 flex flex-col pr-3;

        &--locked {
            .mission-row__header-info {
                @apply text-neutral-300;
            }

            .mission-row__index {
                @apply border-neutral-300 bg-white text-neutral-300;
            }

            .mission-row__header {
                @apply cursor-default text-neutral-300;
            }
        }
    }

    .mission-row__index {
        @apply rounded-full border-4 border-accent bg-accent text-center text-sm text-on-accent;
        line-height: 1.25rem;
        width: 1.75rem;
        height: 1.75rem;

        &--completed {
            @apply bg-white text-accent;
        }
    }

    .mission-row__header {
        @apply mb-6 flex cursor-pointer items-center font-bold;
    }

    .mission-row__header-meta {
        @apply mx-5 hidden items-center text-primary;

        > * {
            @apply ml-1;
        }
    }

    @screen lg {
        .mission-row__header-meta {
            @apply flex;
        }
    }

    .mission-row__header-info {
        @apply absolute left-0 flex w-16 items-center justify-end;
    }

    .mission-row__header-title {
        @apply flex-1 truncate py-2;
    }

    .mission-row__progress-line {
        @apply absolute inset-y-0 left-0 ml-12 w-1 bg-accent;
        /* Half the height of the header row + 1px for the border */
        transform: translateY(calc(2rem + 1px));
    }

    .mission-row__content {
        @apply flex flex-wrap pb-12 pr-5;
    }

    @screen md {
        .mission-row__header {
            @apply text-xl;
        }
    }

    @screen lg {
        .mission-row__content {
            @apply pr-16;
        }
    }
}
