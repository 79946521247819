.training-header__container {
    @apply bg-overlay mb-24 flex flex-col bg-cover;
}

.training-header {
    @apply relative mx-auto mt-20 px-4 pb-32 text-white;
}

.training-header__meta {
    @apply absolute right-0 mr-4 flex flex-col items-center font-medium text-neutral-900;
    top: 100%;
    transform: translateY(-60px);
}

.header__title {
    @apply w-full break-words font-display text-5xl font-extrabold uppercase leading-9;
    letter-spacing: -2.77px;
    text-shadow: 3px 3px 23px rgba(0, 0, 0, 0.3);
}

.training-chapter__header-wrapper {
    @apply mt-4 flex items-center;
}

.training-chapter__progress-line {
    @apply absolute left-0 top-0 -ml-8 w-1;
    bottom: -1px;
}

.training-sprint__progress-line {
    @apply absolute left-0 top-0 -ml-8 w-1;
    /* compensate bottom border */
    bottom: -1px;
}

.training-sprint__progress-circle {
    @apply absolute left-0 z-10 -ml-8 h-8 w-8 rounded-full border-4 text-center text-sm font-bold leading-6;
    /* Compensate with line width */
    transform: translateX(calc(-50% + 2px));

    &--completed {
        @apply border-accent bg-accent text-on-accent;
    }

    &--unlocked {
        @apply border-accent bg-white text-accent;
    }

    &--locked {
        @apply border-neutral-300 bg-white text-neutral-300;
    }
}

/* Don't let the progress bar stick out at the end */
.training-content > :last-child > .training-sprint__progress-line {
    transform: translateY(-50%);
}

@screen sm {
    .training-header__container {
        @apply mb-16;
    }

    .header__title {
        @apply text-6xl;
        line-height: 4rem;
    }
}

@screen lg {
    .header__title {
        font-size: 5rem;
        line-height: 5rem;
    }
}

@screen xl {
    .header__title {
        font-size: 6rem;
        line-height: 6rem;
    }
}
