@layer components {
    .training-card-container {
        @apply relative w-full px-2 pt-10 pb-5 text-left text-on-primary;
    }

    .training-card {
        @apply flex w-full flex-col justify-between px-8 pt-16 pb-12;
        height: 350px;

        &--primary {
            @apply bg-primary text-on-primary;
        }

        &--secondary {
            @apply bg-white text-neutral-900;
            box-shadow: 0 10px 24px 0 rgba(29, 42, 68, 0.12);
        }

        &--locked {
            @apply bg-neutral-100 text-neutral-900;
            box-shadow: 0 10px 24px 0 rgba(29, 42, 68, 0.12);

            & + .training-card__icon {
                @apply bg-neutral-100 text-neutral-900;
            }
        }
    }

    .training-card__icon {
        @apply absolute top-0 h-20 w-20 overflow-visible rounded-full border-8 border-white bg-primary;
        left: 50%;
        transform: translateX(-50%);
    }

    .training-card__title {
        @apply text-3xl font-extrabold uppercase leading-tight;
    }

    @screen md {
        .training-card-container {
            @apply w-1/2;
        }
    }

    @screen lg {
        .training-card-container {
            @apply w-1/3;
        }
    }
}
