@layer components {
    .leaderboard-row {
        @apply my-4 flex h-24 items-center border-b border-neutral-100 bg-white p-3;
    }

    .leaderboard-row--raised {
        @apply border-none;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    }

    .leaderboard-row__rank-circle {
        @apply mx-auto flex h-8 w-8 items-center justify-center rounded-full bg-neutral-500 text-sm font-bold text-neutral-900;
    }

    .leaderboard-row__rank {
        @apply w-10;
    }

    .leaderboard-row__rank-1,
    .leaderboard-row__rank-2,
    .leaderboard-row__rank-3 {
        @apply h-16 w-full bg-contain bg-no-repeat;
    }

    .leaderboard-row__rank-1 {
        background-image: url('../../img/portal/ranking/1.svg');
    }

    .leaderboard-row__rank-2 {
        background-image: url('../../img/portal/ranking/2.svg');
    }

    .leaderboard-row__rank-3 {
        background-image: url('../../img/portal/ranking/3.svg');
    }

    .leaderboard-row__default-picture {
        @apply mx-4 h-16 w-16 rounded-full bg-neutral-300 bg-center bg-no-repeat;
        background-image: url('../../img/portal/default-profile-picture.svg');
        background-size: 60%;
        box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.2);
        background-blend-mode: soft-light;
    }

    .leaderboard-row__profile-picture {
        @apply mx-4 h-16 w-16 rounded-full bg-cover bg-center;
        box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.2);
    }

    .leaderboard-row__name {
        @apply font-bold;
    }

    .leaderboard-row__group {
        @apply text-xs;
    }

    .leaderboard-row__info {
        @apply flex-1 leading-tight text-neutral-900;
    }

    .leaderboard-row__score {
        @apply mr-0 text-right text-xl font-bold text-neutral-900;
    }

    @screen md {
        .leaderboard-row__score {
            @apply mr-10;
        }
    }
}
