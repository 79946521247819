@layer components {
    .progress-bar__container {
        @apply mx-auto w-full bg-white py-4 px-8;
        border-radius: 1rem;
    }

    .progress-bar {
        @apply relative h-1 w-full flex-row items-start rounded-full bg-neutral-300;
    }

    .progress-bar__line {
        @apply h-1 w-full rounded-full bg-accent;
        transition: transform 450ms ease-out;
        transform: scaleX(1);
        transform-origin: left center;
    }

    .progress-bar__pill {
        @apply absolute left-0 inline-block select-none rounded-full bg-accent px-2 text-sm font-extrabold text-on-accent;
        top: 50%;
        transition: left 450ms ease-out;
        transform: translate(-50%, -50%);
    }

    .progress-bar__dot {
        @apply absolute h-3 w-3 rounded-full border-2 border-accent bg-white;
        top: 50%;
        transition: left 450ms ease-out;
        transform: translate(-50%, -50%);
    }
}
