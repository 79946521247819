@layer components {
    .footer {
        @apply mt-12;
    }

    .footer__content {
        @apply mx-auto flex flex-col items-center justify-between border-t border-neutral-100 py-8 px-4;
    }

    .footer__logo {
        @apply mx-auto my-2 h-12 w-32 bg-contain bg-center bg-no-repeat;
        background-image: url('../../img/portal/InThere_logo_2020.svg');
    }

    .footer__slogan {
        @apply mx-4 text-center font-bold uppercase;
    }
}
