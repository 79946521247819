@layer utilities {
    .bg-overlay {
        background-blend-mode: darken;
        background-color: hsla(0, 0%, 0%, 0.5);
    }

    .bg-overlay-light {
        background-blend-mode: darken;
        background-color: hsla(0, 0%, 0%, 0.25);
    }
}
