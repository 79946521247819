@layer components {
    @responsive {
        .icon {
            @apply inline-block h-4 w-4 fill-current;
            line-height: 1;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-width: 1.5px;
        }

        .icon--bold {
            fill: currentColor;
        }

        .icon-gutter {
            @apply bg-no-repeat pl-8;
        }

        .icon--sm {
            @apply h-3 w-3;
        }

        .icon--md {
            @apply h-5 w-5;
        }

        .icon--lg {
            @apply h-6 w-6;
        }

        .icon--xl {
            @apply h-12 w-12;
        }
    }

    /**
 * ONLY USE THIS FOR LEGACY COURSE ICONS
 * @deprecated
 */
    .legacy-icon {
        @apply h-full w-full p-3;
    }
}
